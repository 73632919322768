@use 'variables';

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 var(--spacing-sm);
}

.dialog {
	bottom: auto;
	width: min(50em, 100%);
	height: auto;
	max-height: 100%;
	overflow: hidden !important;
}

@media (min-width: variables.$breakpoint-md) {
	.closeButton {
		top: var(--spacing-md);
		right: var(--spacing-md);
	}
	.container {
		width: 880px;
		margin: 0 auto;
	}
}
