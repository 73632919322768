@use 'mixins';
@use 'variables';
@use 'functions';

.heading {
	font: max(2.5rem, 3.5rem + 9vw - 2.5rem) / 0.9 var(--font-stack-title);
	text-align: center;
	text-transform: uppercase;
}

.content {
	@include mixins.stack($size: md);

	padding: var(--spacing-sm);

	padding-top: 3rem;

	background-color: var(--color-accent-ocean);
}

.title {
	@include mixins.font-t4($weight: 700);

	margin-right: calc(var(--dialog-close-button-icon-size) + var(--spacing-sm));
}

.copy {
	@include mixins.font-t5;
	display: block;
}

.illustrations,
.link {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.link {
	width: fit-content;
	padding-right: var(--spacing-md);
	padding-left: var(--spacing-md);

	border: 0;

	background-color: var(--color-core-black);

	color: var(--color-core-white);
}

.heading-separator {
	display: inline-block;
	position: relative;
	margin-left: var(--spacing-md);
}

.illustrations {
	display: none;

	justify-content: center;
	height: 5.45256rem;

	margin-top: var(--spacing-sm);
	margin-right: var(--spacing-md);
	margin-left: var(--spacing-md);

	gap: var(--spacing-md);

	&__mobile {
		display: flex;
		height: unset;
	}
}

.illustration {
	--illustration-max-width: #{functions.fluid-length(
			$min: 5.1285,
			$max: 6.45256
		)};
	display: inline-block;
	width: 100%;
	img {
		top: initial;
	}
}

@media (min-width: variables.$breakpoint-md) {
	.heading {
		font-size: 6rem;
	}
	.content {
		padding: var(--spacing-md);
	}

	.illustrations {
		display: inline-flex;
		height: 5rem;
		margin-top: inherit;
		&__mobile {
			display: none;
		}
	}
	.copy,
	.link {
		font-size: 1.3rem;
	}
	.copy {
		text-align: center;
	}
	.link {
		padding-right: var(--spacing-lg);
		padding-left: var(--spacing-lg);
	}
}
