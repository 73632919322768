@use 'variables';

@keyframes rotate {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.reservation-widget {
	display: none;
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	justify-content: center;
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.8);

	&--is-visible {
		display: block;
	}

	&--is-loaded {
		overflow: auto;

		color: var(--color-core-black);
	}

	&__header {
		padding: var(--spacing-md);

		transition:
			opacity 0.4s ease,
			visibility 0.4s ease;

		background-color: var(--header-background, var(--color-brand-pink));

		color: var(--color-core-black);
	}

	&__modal {
		position: relative;
		width: 100%;
		max-width: 43.75rem;
		height: auto;
		margin-top: 0;

		iframe {
			width: 100%;
			height: clamp(66rem, 300vw, 73rem);

			border: none;

			opacity: 1;
		}
	}

	&__content {
		display: flex;
		visibility: hidden;
		flex-direction: column;

		transition:
			opacity 0.4s ease,
			visibility 0.4s ease;

		opacity: 0;
	}

	&--is-loaded &__content {
		visibility: visible;

		opacity: 1;
	}

	&__close {
		position: absolute;
		top: var(--spacing-sm);
		right: var(--spacing-sm);
		width: 1.5rem;
		height: 1.5rem;

		svg {
			transform: rotate(45deg);

			fill: currentColor;
		}
	}

	&__loader {
		display: block;
		position: absolute;
		top: 50vh;
		left: 50vw;
		width: 1.5rem;
		height: 1.5rem;

		transform: translate(-50%, -50%);
		animation: rotate 1s linear infinite;

		background-color: var(--color-core-white);
	}

	&--is-loaded &__loader {
		display: none;
	}

	@media (min-width: variables.$breakpoint-sm) {
		&__modal {
			max-width: 43.75rem;
			margin: 0 auto;
			margin-top: var(--spacing-md);
			margin-bottom: var(--spacing-md);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&__modal {
			margin-top: var(--spacing-xl);
			margin-bottom: var(--spacing-xl);
		}
	}
}
